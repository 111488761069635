import { render, staticRenderFns } from "./Media.vue?vue&type=template&id=6460d0fe&scoped=true&"
import script from "./Media.vue?vue&type=script&lang=js&"
export * from "./Media.vue?vue&type=script&lang=js&"
import style0 from "./Media.vue?vue&type=style&index=0&id=6460d0fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6460d0fe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Vimeo: require('/vercel/path0/components/Vimeo.vue').default,VideoPlayer: require('/vercel/path0/components/VideoPlayer.vue').default,StrapiImage: require('/vercel/path0/components/StrapiImage.vue').default})
