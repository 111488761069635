//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Expo } from 'gsap'
export default {
  props: {
    isIndex: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      intersectionOptions: {
        root: null,
        rootMargin: '0% 0% -5% 0%',
        threshold: 0,
      },
      tl: null,
    }
  },
  mounted() {
    let results = ''
    if (
      (this.$refs.content.innerHTML.includes('h1') ||
        this.$refs.content.innerHTML.includes('h2')) &&
      !this.$route.query.preview
    ) {
      this.tl = this.$gsap.timeline({ paused: true })
      results = this.$splitting({ target: this.$refs.content, by: 'lines' })
      this.$gsap.set(this.$refs.content, { opacity: 1 })

      results[0].lines.forEach((line, index) => {
        this.tl.from(
          line,
          1 + 0.2 * index,
          {
            y: '30%',
            opacity: 0,
            delay: 0.1 * index,
            ease: Expo.easeOut,
          },
          this.isIndex ? 1 : 0
        )
      })
    } else {
      this.tl = this.$gsap.timeline({ paused: true })
      this.$gsap.set(this.$refs.content, { opacity: 1 })

      this.tl.from(
        this.$refs.content,
        1,
        {
          y: '30px',
          opacity: 0,
          delay: 0.2,
          ease: Expo.easeOut,
        },
        0
      )
    }
  },
  methods: {
    onWaypoint({ going, direction }) {
      if (
        going === this.$waypointMap.GOING_IN ||
        (going === this.$waypointMap.GOING_OUT &&
          direction === this.$waypointMap.DIRECTION_BOTTOM)
      ) {
        if (this.tl) this.tl.play()
      }
    },
  },
}
