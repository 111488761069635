//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import utils from '@/mixins/utils'
import Media from '@/components/Media'
import Animation from '@/components/Animation'

export default {
  components: {
    Media,
    Animation,
  },
  mixins: [utils],
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
    size: {
      type: String,
      default: 'two',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
}
