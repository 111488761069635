//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VLazyImage from 'v-lazy-image/v2/v-lazy-image.es.js'

export default {
  components: {
    VLazyImage,
  },
  data() {
    return {
      sizes: [500, 800, 1200, 1500, 2000],
      loaded: false,
    }
  },
  props: {
    image: {
      type: Object,
      default: () => {
        return null
      },
    },
    aspectRatio: {
      type: String,
      default: null,
    },
    preload: {
      type: Boolean,
      default: true,
    },
    carousel: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'full', // small, medium, full
    },
  },
  computed: {
    srcset() {
      let srcset = ''
      if (this.size === 'small') {
        srcset += this.getUrl(this.sizes[0]) + ' ' + this.sizes[0]
      }
      if (this.size === 'medium') {
        srcset += this.getUrl(this.sizes[1]) + ' ' + this.sizes[1]
      }
      if (this.size === 'full') {
        for (let i = 0; i < this.sizes.length; i++) {
          srcset += this.getUrl(this.sizes[i]) + ' ' + this.sizes[i] + 'w, '
        }
      }

      return srcset
    },
    aspect() {
      return this.aspectRatio === '16:9'
        ? 0.562
        : this.aspectRatio === '1:1'
        ? 1
        : this.image.attributes.height / this.image.attributes.width
    },
    src() {
      return this.size === 'small'
        ? this.getUrl(500)
        : this.size === 'medium'
        ? this.getUrl(800)
        : this.getUrl(1500)
    },
  },
  methods: {
    getUrl(width) {
      if (this.image.attributes.mime === 'image/gif') {
        return this.image.attributes.url
      } else {
        return this.$cloudinary.image.url(this.image.attributes.hash, {
          gravity: 'auto:subject',
          width,
          height:
            this.aspectRatio === '16:9'
              ? Math.ceil(width / 1.77777)
              : this.aspectRatio === '1:1'
              ? width
              : null,
          crop: 'fill',
          dpr: 'auto',
          loading: 'lazy',
          quality: '70',
        })
      }
    },
  },
  mounted() {
    const self = this
    setTimeout(function () {
      self.loaded = true
    }, 500)
  },
}
