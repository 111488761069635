export default {
  methods: {
    getType(input) {
      let type = ''
      if (input && input.data && input.data.id) {
        switch (input.data.id) {
          case '1':
            type = 'creative'
            break;
          case '4':
            type = 'pictures'
            break;
          case '2':
            type = 'ventures'
            break;
            case '5':
              type = 'strategy'
            break;
            case '6':
              type = 'production'
              break;
          default:
            type = 'global'
            break;
        }
      }
      return type
    },
    getParentType(input) {
      let type = ''
      if (input && input.data && input.data.id) {
        switch (input.data.id) {
          case '1':
            type = 'creative'
            break;
          case '4':
            type = 'pictures'
            break;
          case '2':
            type = 'ventures'
            break;
            case '5':
              type = 'creative'
            break;
            case '6':
              type = 'creative'
              break;
          default:
            type = 'global'
            break;
        }
      }
      return type
    },
    getMenuSlug(item) {
      if (item.page_relation.data) {
        return '/' + item.page_relation.data.attributes.slug + (item.page_query || '')
      } else if (item.project_relation.data) {
        return '/case/' + item.project_relation.data.attributes.slug
      } else {
        return item.url
      }
    },
  }
}
