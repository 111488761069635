//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Mousewheel, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import { Draggable } from 'gsap/Draggable'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import StrapiImage from '@/components/StrapiImage'
import VideoPlayer from '@/components/VideoPlayer'
import Vimeo from '@/components/Vimeo'
import 'swiper/swiper.min.css'
import 'swiper/modules/scrollbar.min.css'
export default {
  components: {
    StrapiImage,
    VideoPlayer,
    Vimeo,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
    type: {
      type: String,
      default: 'grid',
    },
    vimeo: {
      type: String,
      default: null,
    },
    preload: {
      type: Boolean,
      default: true,
    },
    aspectRatio: {
      type: String,
      default: 'original',
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'full', // small, medium, full
    },
  },
  data() {
    return {
      swiperOptions: {
        observer: true,
        observeParents: true,
        grabCursor: true,
        slidesPerView: 'auto',
        spaceBetween: 12,
        modules: [Mousewheel, Scrollbar],
        scrollbar: {
          el: '#swiper-scrollbar-' + this._uid,
          draggable: true,
        },
        mousewheel: {
          forceToAxis: true,
        },
      },
      isMoving: false,
    }
  },
  setup() {
    return {
      modules: [Mousewheel, Scrollbar],
    }
  },
  mounted() {
    if (this.type === 'stacked') {
      this.$gsap.registerPlugin(Draggable)
      this.$gsap.registerPlugin(ScrollTrigger)
      this.$gsap.set(this.$refs.media.children, {
        x: () => {
          return this.randomNumber(15, 35) + 'vw'
        },
        y: () => {
          return this.randomNumber(0, 15) + 'vh'
        },
      })
      this.$gsap
        .timeline({
          paused: true,
          scrollTrigger: {
            trigger: this.$el,
            start: 'top bottom',
          },
        })
        .set(this.$refs.media.children, {
          autoAlpha: 1,
          stagger: 0.5,
        })

      Draggable.create(this.$refs.media.children, {
        type: 'x,y',
        edgeResistance: 0.65,
        bounds: this.$refs.media,
        inertia: true,
      })
    }
  },
  // beforeDestroy() {
  //   this.$gsap.to(this.$el, 0.5, { scale: 0.9 })
  // },
  methods: {
    randomNumber(min, max) {
      return Math.random() * (max - min) + min
    },
    handleClickSlide(index) {
      this.swiper.slideTo(index, 500, false)
    },
    touchStart() {
      this.isMoving = true
    },
    touchEnd() {
      this.isMoving = false
    },
  },
}
